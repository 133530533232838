@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
mat-datepicker-content {
  background-color: white !important;
  box-shadow: 0 0.5625rem 1.875rem 0 rgba(0, 0, 0, 0.13) !important;
  border-radius: 0.625rem !important;

  .mat-calendar-body-selected {
    background-color: $theme-primary;
    color: white;
  }
}
