@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-select-panel-wrap {
  ::-webkit-scrollbar {
    width: 0.375rem;
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    transition: background-color 0.15s;
    background-color: rgb(194, 194, 194);
    border-radius: 0.625rem;

    &:hover {
      background-color: rgb(125, 125, 125);
    }
  }

  .wizbii-panel {
    &.mat-select-panel {
      background-color: white;
      box-shadow: 0 0.5625rem 1.875rem 0 rgba(0, 0, 0, 0.13);
      margin-top: 2.1875rem;
      border-radius: 0.625rem;
    }

    .mat-option {
      transition: color 0.15s, background-color 0.15s;
      color: $wizbii-black;

      &:hover:not(.mat-option-disabled),
      &.mat-active {
        background-color: #f6f8fb;
        color: $theme-primary;
      }

      &.mat-option-disabled {
        opacity: 0.5 !important;
      }
    }
  }
}
