@import 'variables';

.ng5-slider {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;

  .ng5-slider-bar {
    background: #e5e4ff !important;
    height: 0.375rem !important;
  }

  .ng5-slider-selection {
    background: $theme-primary !important;
  }

  .ng5-slider-pointer {
    width: 1.625rem !important;
    height: 1.625rem !important;
    top: -0.6rem !important;
    transform: translateX(-0.437rem) !important;
    background-color: $theme-primary !important;
    border: 0.25rem solid white !important;
    outline: none;

    &::after {
      display: none !important;
    }
  }

  .ng5-slider-bubble {
    bottom: 1.375rem !important;
    background: transparentize($theme-primary, 0.75) !important;
    transform: translateX(-0.5rem) !important;
    color: $theme-primary !important;
    font-weight: bold !important;
    padding: 0 0.5rem 0 0.5rem !important;
    border-radius: 1rem !important;
    font-size: 0.75rem !important;
    transition: left 0.4s ease-out;

    &.ng5-slider-limit {
      background: transparent !important;
      color: transparent !important;
    }
  }

  .ng5-slider-limit {
    font-weight: bold !important;
    color: $theme-primary !important;
  }

  .ng5-slider-tick {
    width: 0.15rem !important;
    height: 0.625rem !important;
    margin-left: 0.25rem !important;
    border-radius: 0 !important;
    background-color: $theme-primary !important;
    top: 0.065rem !important;

    &.ng5-slider-selected {
      background: $theme-primary !important;
    }
  }

  .ng5-slider-tick-legend {
    white-space: nowrap !important;
    font-size: 0.75rem !important;
  }

  .ng5-slider-inner-tooltip {
    opacity: 0.4;
    color: $wizbii-black;
    font-size: 0.75rem;
    font-weight: 500;
  }
}
