@import 'variables';

$colors-list: (
  'blue': $wizbii-blue,
  'pink': $wizbii-pink,
  'green': $wizbii-green,
  'purple': $wizbii-purple,
  'yellow': $wizbii-yellow,
  'black': $wizbii-black,
  'red': $wizbii-red,
  'orange': #ff9544,
);

.badge {
  border: 0.0625rem solid;
  border-radius: 0.25rem;
  font-size: 0.75em;
  padding: 0.1875rem 0.3125rem;
  white-space: nowrap;
}

.badge-no-border {
  font-size: 0.75em;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
  white-space: nowrap;
}

@each $name, $color in $colors-list {
  .badge--#{$name} {
    background-color: lighten($color, 22%);
    color: $color;
    border-color: lighten($color, 5%);
  }

  .badge-no-border--#{$name} {
    background-color: lighten($color, 19%);
    color: $color;
  }
}
