@import 'variables';

// stylelint-disable selector-max-specificity, selector-no-qualifying-type, selector-type-no-unknown, no-descending-specificity
.mat-tooltip {
  background-color: #ededff;
  color: $wizbii-purple !important;
  border-radius: 0.4375rem;
  border: 0.0625rem solid $wizbii-purple;
  padding: 0.875rem !important;
  font-size: 0.75rem;
  font-weight: 500;
  white-space: pre-line;
}
