@mixin visually-hidden {
  position: absolute !important;
  overflow: hidden;
  // stylelint-disable unit-blacklist
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  // stylelint-enable unit-blacklist
}

.visually-hidden {
  // https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
  @include visually-hidden;
}
